<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
            label-width="68px">
            <div class="x-bc">
              <div>
                <el-form-item label="字典名称" prop="dictName">
                  <el-input v-model="queryParams.dictName" placeholder="请输入字典名称" clearable size="mini"
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="字典类型" prop="dictType">
                  <el-input v-model="queryParams.dictType" placeholder="请输入字典类型" clearable size="mini"
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                  <el-select v-model="queryParams.status" placeholder="字典状态" clearable size="mini">
                    <el-option v-for="dict in dict.type.sys_normal_disable" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
                  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="业务字典" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isAddBtn="false" :isDeleteBtn="false" :isExportBtn="false">
            <template #specialDiyBtn>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
                v-hasPermi="['system:dict:list:add']">新增</el-button>

              <el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                v-hasPermi="['system:dict:list:edit']">修改</el-button>

              <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
                v-hasPermi="['system:dict:list:remove']">删除</el-button>
            </template>
          </operatingButton>

          <el-table v-loading="loading" :data="typeList" @selection-change="handleSelectionChange"
            @row-click="handleRowClick" ref="multipleTable" border :max-height="tableHeight" :height="tableHeight"
            style="width: 100%">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="字典编号" align="center" prop="dictId" />
            <el-table-column label="字典名称" align="center" prop="dictName" :show-overflow-tooltip="true" />
            <el-table-column label="字典类型" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <router-link :to="'/system/dict-data/business/index/' + scope.row.dictId" class="link-type">
                  <span>{{ scope.row.dictType }}</span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.sys_normal_disable" :value="scope.row.status" />
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="remark" :show-overflow-tooltip="true" />
            <el-table-column label="创建时间" align="center" prop="createTime" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                  v-hasPermi="['business:dict:edit']">修改</el-button>
                <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                  v-hasPermi="['business:dict:remove']">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>
    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar> -->

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->

    <!-- 添加或修改参数配置对话框(加上标签v-dialogDrag="true"可以移动窗口) -->
    <!-- <el-dialog
      :title="title"
      :visible.sync="open"
      width="500px"
      append-to-body
      v-dialogDrag="true"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="字典名称" prop="dictName">
          <el-input v-model="form.dictName" placeholder="请输入字典名称" />
        </el-form-item>
        <el-form-item label="字典类型" prop="dictType">
          <el-input v-model="form.dictType" placeholder="请输入字典类型" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio
              v-for="dict in dict.type.sys_normal_disable"
              :key="dict.value"
              :label="dict.value"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog> -->

    <c-dialog :title="title" :width="600" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="字典名称" prop="dictName">
            <el-input v-model="form.dictName" placeholder="请输入字典名称" />
          </el-form-item>
          <el-form-item label="字典类型" prop="dictType">
            <el-input v-model="form.dictType" placeholder="请输入字典类型" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio v-for="dict in dict.type.sys_normal_disable" :key="dict.value"
                :label="dict.value">{{ dict.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import {
  listBusiness,
  getType,
  delType,
  addType,
  updateType,
  refreshCache
} from '@/api/system/dict/type'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'Dict',
  dicts: ['sys_normal_disable'],
  components: {
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 字典表格数据
      typeList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      },
      // 表单参数
      form: {
        // 字典类型(0: 系统字典; 1: 业务字典)
        category: 1
      },
      // 表单校验
      rules: {
        dictName: [
          { required: true, message: '字典名称不能为空', trigger: 'blur' }
        ],
        dictType: [
          { required: true, message: '字典类型不能为空', trigger: 'blur' }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created() {
    this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 136
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listBusiness({
          ...this.queryParams,
          ...this.dateRange,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.typeList = res.rows
        //表格多选
        this.typeList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    /** 查询字典类型列表 */
    getList() {
      this.loading = true
      listBusiness(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.typeList = response.rows
          this.total = response.total
          this.loading = false
        }
      )
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        dictId: undefined,
        dictName: undefined,
        dictType: undefined,
        status: '0',
        category: 1,
        remark: undefined
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.resetForm('queryForm')
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加字典类型'
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.dictId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const dictId = row.dictId || this.ids
      getType(dictId).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改字典类型'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.dictId != undefined) {
            updateType(this.form).then(response => {
              this.$modal.msgSuccess('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            this.form.category = 1
            addType(this.form).then(response => {
              this.$modal.msgSuccess('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const dictIds = this.ids
      this.$modal
        .confirm('是否确认删除字典编号为"' + dictIds + '"的数据项？')
        .then(function () {
          return delType(dictIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => { })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        'system/dict/type/export',
        {
          ...this.queryParams
        },
        `type_${new Date().getTime()}.xlsx`
      )
    },
    /** 刷新缓存按钮操作 */
    handleRefreshCache() {
      refreshCache().then(() => {
        this.$modal.msgSuccess('刷新成功')
        this.$store.dispatch('dict/cleanDict')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
</style>

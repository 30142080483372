<template>
    <div class="container" id="wrap">
        <UserInfo :style="{height: tableHeight + 'px'}"></UserInfo>
    </div>
</template>

<script>
import UserInfo from '@/views/components/userInfo/index.vue'

export default {
    name: 'index',
    components: {
        UserInfo
    },
    data(){
        return{
            vivwH: 0, //页面高度
        }
    },
    computed: {
        tableHeight() {
            return this.vivwH - 80
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.vivwH = document.getElementById('wrap').clientHeight
        })
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.vivwH = document.getElementById('wrap').clientHeight
        },
    }
}
</script>

<style lang="scss" scoped>
.container {
    margin-top: 80px;
}
</style>

<template>
    <div class="container">
        <Material :isDialog="false"></Material>
    </div>
</template>

<script>
import Material from '@/views/components/material/index.vue'

export default {
    name: 'index',
    components: {
        Material
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 10px;
    background: #e9e9e9;

    .btnList {
        padding: 10px;
    }


}
</style>

<template>
  <div class="view-content">
    <!-- 内容区域 -->
    <div class="content">
      <div class="view-item" v-for="(item) in data" :key="item.upgradeId">
        <div class="title1">{{ `${parseTime(item.createTime, '{y}-{m}-{d}')}更新（${item.productName}）` }}</div>
        <div>{{ item.upgradeTitle }}</div>
        <div class="ql-container">
          <div class="ql-editor" v-html="item.upgradeContent" />
        </div>
      </div>
    </div>
    <!-- 导航区域 -->
    <ul class="navs">
      <li v-for="(item, i) in data" :key="item.upgradeId" :class="{ active: active === i }" @click="scrollTo(i)">{{
        `${parseTime(item.createTime, '{y}-{m}-{d}')}更新（${item.productName}）` }}</li>
    </ul>
  </div>
</template>
  
<script>
import { getAllLogList } from "@/api/tenant/base/upgradeLogManage";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  props: {},
  data() {
    return {
      data: [],
      active: 0 // 当前激活的导航索引
    };
  },
  async mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll, false);
    try {
      this.data = await getAllLogList();
    } catch (error) { }
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".content .view-item");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop - 90);
      });
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义当前点亮的导航下标

      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n;
        }
      }
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      let targetOffsetTop =
        document.querySelector(`.content .view-item:nth-child(${index + 1})`)
          .offsetTop;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50;
      // 判断是往下滑还是往上滑

      if (scrollTop > targetOffsetTop) {
        // 往上滑
        targetOffsetTop = targetOffsetTop - 90;
        smoothUp();
      } else {
        // 往下滑
        targetOffsetTop = targetOffsetTop - 90;
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    }
  }
};
</script>
  
<style lang="scss" scoped>
.view-content {
  min-width: 1024px;

  /* 内容区的样式 */
  .content {
    background-color: white;
    width: 650px;
    padding: 6px 12px;

    .view-item {
      .title1 {
        font-size: 20px;
        font-weight: bold;
        padding: 5px 0;
      }

      .content-html {
        padding-left: 30px;
      }
    }
  }

  /* 导航栏的样式 */
  .navs {
    position: fixed;
    top: 90px;
    right: 40px;
  }

  .navs li {
    padding: 0 12px;
    line-height: 26px;
    font-size: 18px;
  }

  /* 当导航被点亮后改变颜色 */
  .navs .active {
    color: #409eff;
  }
}
</style>
  
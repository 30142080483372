<template>
  <div class="container">
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="configSave">保存</el-button>
    </div>
    <!-- @change="
                  (v) => change(v, 'system_BUSINESS_PRODUCE_GODOWN_CONFIG_21')
                " -->
    <cardTitleCom cardTitle="生产仓库设置" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f marB10">
            <div class="x-f">
              <span>生产工厂</span>
              <el-select @change="produceStoreChange" :disabled="isEdit25 == '1'"
                v-model="form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25" class="inputWidth marL5" placeholder="全部"
                filterable clearable value-key="warehouseNumber" size="mini">
                <el-option v-for="item in deptList" :key="item.deptId" :label="item.deptName" :value="item.deptId">
                </el-option>
              </el-select>
            </div>
            <div class="marL20">
              <span style="margin-left: 14px">成品仓库</span>
              <el-select :disabled="
                  isEdit21 == '1' ||
                  !form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25
                " v-model="form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_21" class="inputWidth marL5" placeholder="全部"
                filterable clearable size="mini">
                <el-option v-for="item in storeList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                </el-option>
              </el-select>
            </div>
            <div class="marL20">
              <span>配料间</span>
              <el-select :disabled="
                  isEdit22 == '1' ||
                  !form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25
                " v-model="form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_22" class="inputWidth marL5" placeholder="全部"
                filterable clearable value-key="warehouseNumber" size="mini">
                <el-option v-for="item in storeList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="x-f">
            <div>
              <span>原料仓库</span>
              <el-select :disabled="
                  isEdit23 == '1' ||
                  !form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25
                " v-model="form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_23" class="inputWidth marL5" placeholder="全部"
                filterable clearable value-key="warehouseNumber" size="mini">
                <el-option v-for="item in storeList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                </el-option>
              </el-select>
            </div>
            <div class="marL20">
              <span>半成品仓库</span>
              <el-select :disabled="
                  isEdit24 == '1' ||
                  !form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25
                " v-model="form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_24" class="inputWidth marL5" placeholder="全部"
                filterable clearable value-key="warehouseNumber" size="mini">
                <el-option v-for="item in storeList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="生产参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <div>
            控制门店订货单每天做
            <el-input style="width: 60px" size="mini" :min="1" type="number" class="input underline-input"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_11"></el-input>张，(注:不填默认不控制)
          </div>
          <div class="marT15">
            生产计划单分生产组制作
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_12">
            </el-switch>
          </div>
          <!-- <div class="marT15">
            产品入库强制进行质检
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_13"
            >
            </el-switch>
          </div> -->
          <div class="marT15">
            允许多次入库
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_14">
            </el-switch>
          </div>
          <div class="marT15">
            允许多次分货
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_15">
            </el-switch>
          </div>
          <div class="marT15">
            产品入库单审核前强制进行质检
            <el-switch class="marL20" active-value="Y" inactive-value="N"
              v-model="form.system_BUSINESS_PRODUCE_CONFIG_16">
            </el-switch>
          </div>
          <div class="marT15">
            智能配货优先级规则
            <el-radio-group v-model="form.system_BUSINESS_PRODUCE_CONFIG_120">
              <el-radio :label="1">按指定排序配货</el-radio>
              <el-radio :label="2">按订货排名配货</el-radio>
            </el-radio-group>
          </div>
          <el-form>
            <el-form-item>
              <div class="marT20">
                <span>若您允许生产计划单的数量大于销售订单的数量，请勾选</span>
                <div class="x-f">
                  <el-checkbox v-model="form.system_BUSINESS_PRODUCE_CONFIG_17">允许生产计划单数量大于销售订单可生产数量</el-checkbox>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <div>
                <span>若您允许生产领料单的数量大于生产计划单的计划投料数量，请勾选</span>
                <div class="x-f">
                  <el-checkbox v-model="form.system_BUSINESS_PRODUCE_CONFIG_18">允许数量大于计划投料数量</el-checkbox>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="若您希望控制产品入库前必须先领料，请选择控制强度及控制项" class="compact-form-item"></el-form-item>
            <el-radio class="marT10" v-model="form.system_BUSINESS_PRODUCE_CONFIG_19" :label="1">不控制</el-radio>
            <el-radio v-model="form.system_BUSINESS_PRODUCE_CONFIG_19" :label="2">提示，允许继续入库</el-radio>
            <el-radio v-model="form.system_BUSINESS_PRODUCE_CONFIG_19" :label="3">提示，不允许继续入库</el-radio>
            <el-form-item>
              <div class="marT15">
                <span>若您允许产品入库单的数量大于生计划单的数量，请勾选</span>
                <div class="x-f">
                  <el-checkbox v-model="form.system_BUSINESS_PRODUCE_CONFIG_110">允许入库数量大于生产计划数量</el-checkbox>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import { listStore } from '@/api/goods/store' //仓库信息
import {
  produceListAPI,
  produceAddUpdateAPI
} from '@/api/system/maintain/param/produceParam'
import { listSimpleQuery } from '@/api/system/dept' //部门
// import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
export default {
  name: 'produceParam',
  components: {
    cardTitleCom,
    bottomPage
  },
  data() {
    return {
      isEdit21: '0',
      isEdit22: '0',
      isEdit23: '0',
      isEdit24: '0',
      isEdit25: '0',
      deptList: [], //部门数组
      form: {
        system_BUSINESS_PRODUCE_CONFIG_11: '',
        system_BUSINESS_PRODUCE_CONFIG_110: true,
        system_BUSINESS_PRODUCE_CONFIG_12: false,
        system_BUSINESS_PRODUCE_CONFIG_13: '',
        system_BUSINESS_PRODUCE_CONFIG_14: '',
        system_BUSINESS_PRODUCE_CONFIG_15: '',
        system_BUSINESS_PRODUCE_CONFIG_16: true,
        system_BUSINESS_PRODUCE_CONFIG_17: true,
        system_BUSINESS_PRODUCE_CONFIG_18: true,
        system_BUSINESS_PRODUCE_CONFIG_19: 1,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_21: {
          warehouseName: undefined,
          warehouseNumber: undefined
        },
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_22: {
          warehouseName: undefined,
          warehouseNumber: undefined
        },
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_23: {
          warehouseName: undefined,
          warehouseNumber: undefined
        },
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_24: {
          warehouseName: undefined,
          warehouseNumber: undefined
        },
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_25: {
          warehouseName: undefined,
          warehouseNumber: undefined
        }
      },
      checked: [],
      radio: '',
      companyName: '', // 公司名称
      value: true,
      content2: '提示信息',
      input: '',
      storeList: []
    }
  },
  async created() {
    const res1 = await listStore()
    this.storeList = res1.rows
    const res = await listSimpleQuery()
    this.deptList = res.data
    await this.getDetails()
  },
  methods: {
    produceStoreChange(val) {
      if (!val) {
        for (let i = 21; i <= 24; i++) {
          this.form[`system_BUSINESS_PRODUCE_GODOWN_CONFIG_${i}`] = undefined
        }
      }
    },
    // change(e, key) {
    //   console.log("eeee", e);
    //   this.form[key].warehouseName =
    //     this.storeList.find((x) => x.storeId === e)?.storeName || "";
    // },
    async getDetails() {
      try {
        const { data } = await produceListAPI()
        const type1 = ['19']
        const type2 = ['17', '18', '110']
        const k = 'system_BUSINESS_PRODUCE_CONFIG_'
        Object.keys(data).map(x => {
          const type = x.split('_').pop()
          if (type1.includes(type)) {
            data[k + type] = data[k + type] ? Number(data[k + type]) : 1
          } else if (type2.includes(type)) {
            data[k + type] = Boolean(
              !data[k + type] || (data[k + type] && data[k + type] === 'true')
            )
          }
        })
        this.form = data
        for (let i = 21; i <= 25; i++) {
          this[`isEdit${i}`] =
            this.form[`system_BUSINESS_PRODUCE_GODOWN_CONFIG_${i}`].isEdit
        }
        this.form = {
          ...this.form,
          system_BUSINESS_PRODUCE_GODOWN_CONFIG_25: JSON.parse(
            JSON.stringify(
              this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25.warehouseNumber
                ? Number(
                  this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25
                    .warehouseNumber
                )
                : null
            )
          ),
          system_BUSINESS_PRODUCE_GODOWN_CONFIG_24: JSON.parse(
            JSON.stringify(
              this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_24.warehouseNumber
            )
          ),
          system_BUSINESS_PRODUCE_GODOWN_CONFIG_23: JSON.parse(
            JSON.stringify(
              this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_23.warehouseNumber
            )
          ),
          system_BUSINESS_PRODUCE_GODOWN_CONFIG_22: JSON.parse(
            JSON.stringify(
              this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_22.warehouseNumber
            )
          ),
          system_BUSINESS_PRODUCE_GODOWN_CONFIG_21: JSON.parse(
            JSON.stringify(
              this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_21.warehouseNumber
            )
          )
        }
      } catch (error) { }
    },
    configSave() {
      //生产工厂
      this.form = {
        ...this.form,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_25: this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25 ? {
          warehouseNumber: JSON.parse(
            JSON.stringify(this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_25)
          ),
          isEdit: this.isEdit25
        } : null,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_21: this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_21 ? {
          warehouseNumber: JSON.parse(
            JSON.stringify(this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_21)
          ),
          isEdit: this.isEdit21
        } : null,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_22: this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_22 ? {
          warehouseNumber: JSON.parse(
            JSON.stringify(this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_22)
          ),
          isEdit: this.isEdit22
        } : null,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_23: this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_23 ? {
          warehouseNumber: JSON.parse(
            JSON.stringify(this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_23)
          ),
          isEdit: this.isEdit23
        } : null,
        system_BUSINESS_PRODUCE_GODOWN_CONFIG_24: this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_24 ? {
          warehouseNumber: JSON.parse(
            JSON.stringify(this.form.system_BUSINESS_PRODUCE_GODOWN_CONFIG_24)
          ),
          isEdit: this.isEdit24
        } : null
      }

      produceAddUpdateAPI(this.form).then(res => {
        // console.log('售后修改成功', res)
        this.$message.success(res.msg)
        this.getDetails()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::v-deep .underline-input .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
.compact-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.container {
  padding: 8px 10px;
  background: #e9e9e9;
  font-size: 14px;
}
.btnTOP {
  text-align: right;
  padding: 0 10px 6px 0;
}
</style>


<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form
            :model="queryParams"
            ref="queryForm"
            size="small"
            :inline="true"
            v-show="showSearch"
            label-width="68px"
          >
            <div class="x-bc">
              <div>
                <el-form-item label="任务名称" prop="jobName">
                  <el-input
                    v-model="queryParams.jobName"
                    placeholder="请输入任务名称"
                    clearable
                    size="mini"
                    @keyup.enter.native="handleQuery"
                  />
                </el-form-item>
                <el-form-item label="任务组名" prop="jobGroup">
                  <el-select
                    v-model="queryParams.jobGroup"
                    placeholder="请选择任务组名"
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="dict in dict.type.sys_job_group"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="执行状态" prop="status">
                  <el-select
                    v-model="queryParams.status"
                    placeholder="请选择执行状态"
                    clearable
                    size="mini"
                  >
                    <el-option
                      v-for="dict in dict.type.sys_common_status"
                      :key="dict.value"
                      :label="dict.label"
                      :value="dict.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="执行时间">
                  <el-date-picker
                    v-model="dateRange"
                    size="mini"
                    style="width: 240px"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="x-start">
                <!-- <el-form-item> -->
                <el-button type="primary" size="mini" @click="handleQuery"
                  >查询</el-button
                >
                <el-button
                  icon="el-icon-refresh"
                  size="mini"
                  class="marL10"
                  @click="resetQuery"
                  >重置</el-button
                >
                <!-- </el-form-item> -->
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom cardTitle="调度日志" class="brandInfo">
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :isAddBtn="false"
            :isDeleteBtn="false"
            :isExportBtn="true"
          >
            <template #specialDiyBtn>
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['monitor:job:remove']"
                >删除</el-button
              >

              <el-button
                type="success"
                class="iconfont icon-qingchu1"
                size="mini"
                @click="handleClean"
                v-hasPermi="['monitor:job:remove']"
                >清空</el-button
              >

              <!-- <el-button
                type="warning"
                icon="el-icon-download"
                size="mini"
                @click="handleExport"
                v-hasPermi="['monitor:job:export']"
                >导出</el-button
              > -->

              <el-button
                size="mini"
                type="primary"
                @click="handleClose"
                >退出</el-button
              >
            </template>
          </operatingButton>
          <el-table
            v-loading="loading"
            :data="jobLogList"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            ref="multipleTable"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column
              label="日志编号"
              width="80"
              align="center"
              prop="jobLogId"
            />
            <el-table-column
              label="任务名称"
              align="center"
              prop="jobName"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="任务组名"
              align="center"
              prop="jobGroup"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.sys_job_group"
                  :value="scope.row.jobGroup"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="调用目标字符串"
              align="center"
              prop="invokeTarget"
              :show-overflow-tooltip="true"
            />
            <el-table-column
              label="日志信息"
              align="center"
              prop="jobMessage"
              :show-overflow-tooltip="true"
            />
            <el-table-column label="执行状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.sys_common_status"
                  :value="scope.row.status"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="执行时间"
              align="center"
              prop="createTime"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-view"
                  @click="handleView(scope.row)"
                  v-hasPermi="['monitor:job:query']"
                  >详细</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>

    <!-- <right-toolbar
        :showSearch.sync="showSearch"
        @queryTable="getList"
      ></right-toolbar> -->

    <!-- <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    /> -->

    <!-- 调度日志详细 -->
    <!-- <el-dialog
      title="调度日志详细"
      :visible.sync="open"
      width="700px"
      append-to-body
    >
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item label="日志序号：">{{ form.jobLogId }}</el-form-item>
            <el-form-item label="任务名称：">{{ form.jobName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务分组：">{{ form.jobGroup }}</el-form-item>
            <el-form-item label="执行时间：">{{
              form.createTime
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="调用方法：">{{
              form.invokeTarget
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="日志信息：">{{
              form.jobMessage
            }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="执行状态：">
              <div v-if="form.status == 0">正常</div>
              <div v-else-if="form.status == 1">失败</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="异常信息：" v-if="form.status == 1">{{
              form.exceptionInfo
            }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog> -->
    <c-dialog :title="title" :width="800" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" label-width="100px" size="mini">
          <el-row>
            <el-col :span="12">
              <el-form-item label="日志序号：">{{
                form.jobLogId
              }}</el-form-item>
              <el-form-item label="任务名称：">{{ form.jobName }}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务分组：">{{
                form.jobGroup
              }}</el-form-item>
              <el-form-item label="执行时间：">{{
                form.createTime
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="调用方法：">{{
                form.invokeTarget
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="日志信息：">{{
                form.jobMessage
              }}</el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="执行状态：">
                <div v-if="form.status == 0">正常</div>
                <div v-else-if="form.status == 1">失败</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="异常信息：" v-if="form.status == 1">{{
                form.exceptionInfo
              }}</el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="open = false">关 闭</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { getJob } from '@/api/monitor/job'
import { listJobLog, delJobLog, cleanJobLog } from '@/api/monitor/jobLog'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
export default {
  name: 'JobLog',
  dicts: ['sys_common_status', 'sys_job_group'],
  components: {
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
    CDialog
  },
  data () {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 调度日志表格数据
      jobLogList: [],
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        jobName: undefined,
        jobGroup: undefined,
        status: undefined
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 128
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      console.log('wrap', this.vivwH)
      this.searchH = document.getElementById('search-card').clientHeight
      console.log('searchH', this.searchH)
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    const jobId = this.$route.query.jobId
    if (jobId !== undefined && jobId != 0) {
      getJob(jobId).then(response => {
        this.queryParams.jobName = response.data.jobName
        this.queryParams.jobGroup = response.data.jobGroup
        this.getList()
      })
    } else {
      this.getList()
    }
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loading = true
        const res = await listJobLog({
          ...this.queryParams,
          ...this.dateRange,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.jobLogList = res.rows
        //表格多选
        this.jobLogList.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loading = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    /** 查询调度日志列表 */
    getList () {
      this.loading = true
      listJobLog(this.addDateRange(this.queryParams, this.dateRange)).then(
        response => {
          this.jobLogList = response.rows
          this.total = response.total
          this.loading = false
        }
      )
    },
    // 返回按钮
    handleClose () {
      this.$tab.closeOpenPage({ path: "/system/monitor/job" });
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.jobLogId)
      this.multiple = !selection.length
    },
    /** 详细按钮操作 */
    handleView (row) {
      this.title = '调度日志详细'
      this.open = true
      this.form = row
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const jobLogIds = this.ids
      this.$modal
        .confirm('是否确认删除选中的调度？')
        .then(function () {
          return delJobLog(jobLogIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    /** 清空按钮操作 */
    handleClean () {
      this.$modal
        .confirm('是否确认清空所有调度日志数据项？')
        .then(function () {
          return cleanJobLog()
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('清空成功')
        })
        .catch(() => {})
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/monitor/jobLog/export',
        {
          ...this.queryParams
        },
        `log_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
.bigBox {
  border: 1px solid aquamarine;
}
::v-deep .el-button--mini {
  font-size: 11px;
}
</style>

<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
            <div class="x-bc">
              <div>
                <el-form-item label="登录地址" prop="loginIp">
                  <el-input v-model="queryParams.loginIp" placeholder="请输入登录地址" clearable
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="用户名称" prop="userName">
                  <el-input v-model="queryParams.userName" placeholder="请输入用户名称" clearable
                    @keyup.enter.native="handleQuery" />
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
                  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="在线用户" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <el-table v-loading="loading" :data="list" style="width: 100%" :max-height="tableHeight" :height="tableHeight"
            border>
            <el-table-column label="序号" type="index" align="center" />
            <el-table-column label="商户编号" align="center" prop="tenantNo" />
            <el-table-column label="商户名称" align="center" prop="tenantName" />
            <el-table-column label="登录名称" align="center" prop="userName" />
            <el-table-column label="手机号" align="center" prop="telephone" />
            <el-table-column label="部门名称" align="center" prop="deptName" />
            <el-table-column label="登录端口" align="center" prop="deptName">
              <template v-slot="scope">
                <div v-for="(item,index) in dict.type.longin_from" :key="index">
                  <span v-if="scope.row.loginFrom == item.value">{{
                    item.label
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="主机" align="center" prop="loginIp" :show-overflow-tooltip="true" />
            <el-table-column label="登录地点" align="center" prop="loginLocation" :show-overflow-tooltip="true" />
            <el-table-column label="浏览器" align="center" prop="browser" />
            <el-table-column label="操作系统" align="center" prop="os" />
            <el-table-column label="在线时长" align="center" prop="onLineTime" />
            <el-table-column label="最后登录时间" align="center" prop="lastLoginTime" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.lastLoginTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <el-button size="mini" type="text" icon="el-icon-delete" @click="handleForceLogout(scope.row)"
                  v-hasPermi="['monitor:online:forceLogout']">强退
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <bottomPage v-model="queryParams" :getList="getList" :totalCom="total" :isSelectAll='false' />
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import bottomPage from "@/views/components/bottomPage"; //底部分页
import { list, forceLogoutMultiple } from '@/api/monitor/online'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索

export default {
  name: 'Online',
  dicts: ['longin_from'],
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 60
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 85
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.queryParams.tenantId = this.$route.query.tenantId
    this.getList()
  },
  beforeRouteEnter(to, from, next) {
    console.log('路由-00--0-0-:', to, from, next)
    next()
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },

    /** 查询登录日志列表 */
    getList() {
      this.loading = true
      list(this.queryParams).then(response => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    /** 强退按钮操作 */
    handleForceLogout(row) {
      this.$modal
        .confirm('是否确认强退名称为"' + row.userName + '"的用户？')
        .then(function () {
          return forceLogoutMultiple([row.token])
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('强退成功')
        })
        .catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
</style>

<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
            label-width="68px">
            <div class="x-b">
              <div>
                <el-form-item label="登录地址" prop="loginIp">
                  <el-input v-model="queryParams.loginIp" placeholder="请输入登录地址" clearable style="width: 193px"
                    size="mini" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="用户名称" prop="userName">
                  <el-input v-model="queryParams.userName" placeholder="请输入用户名称" clearable style="width: 193px"
                    size="mini" @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                  <el-select v-model="queryParams.status" placeholder="登录状态" clearable style="width: 193px" size="mini">
                    <el-option v-for="dict in dict.type.sys_common_status" :key="dict.value" :label="dict.label"
                      :value="dict.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="登录时间">
                  <el-date-picker v-model="dateRange" style="width: 240px" size="mini" value-format="yyyy-MM-dd"
                    type="daterange" range-separator="-" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              </div>
              <div>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
                  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="登录日志" class="brandInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isAddBtn="false" :isDeleteBtn="false" :isExportBtn="false"
            :isRefreshBtn="true" :multiple="multiple" @handleDelete="handleDelete">
            <template #specialDiyBtn>
              <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
                @click="handleDelete">删除</el-button>
              <!-- v-hasPermi="['monitor:user:log:remove']" -->

              <el-button class="iconfont icon-qingchu1" size="mini" @click="handleClean" type="success">
                清空
              </el-button>
            </template>
          </operatingButton>

          <!--   <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="handleClean"
            >清空</el-button> -->
          <!-- v-hasPermi="['monitor:user:log:remove']" -->

          <!-- <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
            >导出</el-button> -->
          <!--   v-hasPermi="['monitor:user:log:export']" -->
          <el-table ref="tables" border @row-click="handleRowClick" v-loading="loading" :data="list"
            @selection-change="handleSelectionChange" :default-sort="defaultSort" @sort-change="handleSortChange"
            :height="tableHeight" :max-height="tableHeight">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="商户号" align="center" prop="tenantNo" />
            <el-table-column label="商户名称" align="center" prop="tenantName" />
            <el-table-column label="访问编号" align="center" prop="sysUserLogId" />
            <el-table-column label="用户名称" align="center" prop="userName" :show-overflow-tooltip="true" sortable="custom"
              :sort-orders="['descending', 'ascending']" />
            <el-table-column label="登录IP" align="center" prop="loginIp" width="130" :show-overflow-tooltip="true" />
            <el-table-column label="登录地点" align="center" prop="loginLocation" :show-overflow-tooltip="true" />
            <el-table-column label="浏览器" align="center" prop="browser" :show-overflow-tooltip="true" />
            <el-table-column label="操作系统" align="center" prop="os" />
            <el-table-column label="登录状态" align="center" prop="status">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.sys_common_status" :value="scope.row.status" />
              </template>
            </el-table-column>
            <el-table-column label="操作信息" align="center" prop="msg" />
            <el-table-column label="登录日期" align="center" prop="loginTime" sortable="custom"
              :sort-orders="['descending', 'ascending']" width="180">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.loginTime) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- <bottomPage   v-model="queryParams"
  :getList="getList" :idsCom="ids" :totalCom="total"  @selectAllCom="selectAll"/> -->

          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" />
        </div>
      </template>
    </cardTitleCom>

    />
  </div>
</template>

<script>
import { list, delUserLog, cleanUserLog } from "@/api/monitor/userlog";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import bottomPage from "@/views/components/bottomPage"; //底部分页
export default {
  name: "Logininfor",
  dicts: ["sys_common_status"],
  components: {
    cardTitleCom,
    seniorSearch,
    operatingButton,
    bottomPage,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 日期范围
      dateRange: [],
      // 默认排序
      defaultSort: { prop: "loginTime", order: "descending" },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        loginIp: undefined,
        userName: undefined,
        status: undefined,
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 60,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 128;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      console.log("wrap", this.vivwH);
      this.searchH = document.getElementById("search-card").clientHeight;
      console.log("searchH", this.searchH);
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loading = true;
        try {
          const res = await list({
            ...this.dateRange,
            ...this.queryParams,
            pageNum: 1,
            pageSize: this.total,
          });
          // 重新渲染表格
          this.list = res.rows;
          // 表格多选
          this.$nextTick(() => {
            for (const row of this.list) {
              this.$refs.tables.toggleRowSelection(row, true);
            }
            this.loading = false;
          });
        } catch (error) {
          console.error("获取数据发生错误", error);
          this.loading = false;
        }
      } else {
        // 取消全选
        this.$nextTick(() => {
          this.$refs.tables.clearSelection();
        });
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.tables.toggleRowSelection(row);
    },
    /** 查询登录日志列表 */
    getList() {
      this.loading = true;
      list(this.addDateRange(this.queryParams, this.dateRange)).then(
        (response) => {
          this.list = response.rows;
          this.total = response.total;
          this.loading = false;
        }
      );
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.$refs.tables.sort(this.defaultSort.prop, this.defaultSort.order);
      this.handleQuery();
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.sysUserLogId);
      this.multiple = !selection.length;
    },
    /** 排序触发事件 */
    handleSortChange(column, prop, order) {
      this.queryParams.orderByColumn = column.prop;
      this.queryParams.isAsc = column.order;
      this.getList();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const sysUserLogIds = this.ids;
      this.$modal
        .confirm('是否确认删除访问编号为"' + sysUserLogIds + '"的数据项？')
        .then(function () {
          return delUserLog(sysUserLogIds);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => { });
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$modal
        .confirm("是否确认清空所有用户登录日志数据项？")
        .then(function () {
          return cleanUserLog();
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("清空成功");
        })
        .catch(() => { });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "/api/system/monitor/user/login/log/export",
        {
          ...this.queryParams,
        },
        `logininfor_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
    //筛选条件
    .searchCriteria {
      padding-top: 10px;
      .selectFrameUp {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  .brandInfo {
    height: calc(100% - 100px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-button--mini {
  font-size: 11px;
}
</style>

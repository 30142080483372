var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 24 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("基本信息")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("Redis版本"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(_vm.cache.info.redis_version)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("运行模式"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cache.info.redis_mode ==
                                            "standalone"
                                            ? "单机"
                                            : "集群"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("端口"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(_vm.cache.info.tcp_port)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("客户端数"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(_vm.cache.info.connected_clients)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("运行时间(天)"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(_vm.cache.info.uptime_in_days)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("使用内存"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(_vm.cache.info.used_memory_human)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("使用CPU"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(
                                          parseFloat(
                                            _vm.cache.info
                                              .used_cpu_user_children
                                          ).toFixed(2)
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("内存配置"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(_vm.cache.info.maxmemory_human)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("AOF是否开启"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cache.info.aof_enabled == "0"
                                            ? "否"
                                            : "是"
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("RDB是否成功"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cache.info.rdb_last_bgsave_status
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("Key数量"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.dbSize
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(_vm.cache.dbSize) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _c("div", { staticClass: "cell" }, [
                                  _vm._v("网络入口/出口"),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "el-table__cell is-leaf" },
                              [
                                _vm.cache.info
                                  ? _c("div", { staticClass: "cell" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cache.info
                                            .instantaneous_input_kbps
                                        ) +
                                          "kps/" +
                                          _vm._s(
                                            _vm.cache.info
                                              .instantaneous_output_kbps
                                          ) +
                                          "kps"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("命令统计")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c("div", {
                      ref: "commandstats",
                      staticStyle: { height: "420px" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("内存信息")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c("div", {
                      ref: "usedmemory",
                      staticStyle: { height: "420px" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.configSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        attrs: { cardTitle: "公共参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: { "label-width": "100px" },
                        },
                        [
                          _c("div", [_vm._v("企业信息")]),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "marT10",
                              attrs: { label: "公司名称:" },
                            },
                            [
                              _c("el-input", {
                                ref: "companyNameInput",
                                staticClass: "inputWidthM marL20",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入公司名称",
                                },
                                model: {
                                  value:
                                    _vm.form.system_BUSINESS_COMMON_CONFIG_11,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_COMMON_CONFIG_11",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_COMMON_CONFIG_11",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司地址:" } },
                            [
                              _c("el-input", {
                                staticClass: "inputWidthM marL20",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入公司地址",
                                },
                                model: {
                                  value:
                                    _vm.form.system_BUSINESS_COMMON_CONFIG_12,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_COMMON_CONFIG_12",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_COMMON_CONFIG_12",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司电话:" } },
                            [
                              _c("el-input", {
                                staticClass: "inputWidthM marL20",
                                attrs: {
                                  type: "number",
                                  size: "mini",
                                  placeholder: "请输入公司电话",
                                },
                                on: { input: _vm.handleInput },
                                model: {
                                  value:
                                    _vm.form.system_BUSINESS_COMMON_CONFIG_13,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_COMMON_CONFIG_13",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_COMMON_CONFIG_13",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司传真:" } },
                            [
                              _c("el-input", {
                                staticClass: "inputWidthM marL20",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入公司传真",
                                },
                                model: {
                                  value:
                                    _vm.form.system_BUSINESS_COMMON_CONFIG_14,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_COMMON_CONFIG_14",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_COMMON_CONFIG_14",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "公司邮编:" } },
                            [
                              _c("el-input", {
                                staticClass:
                                  "inputWidthM marL20 compact-form-item",
                                attrs: {
                                  type: "number",
                                  size: "mini",
                                  placeholder: "请输入公司邮编",
                                },
                                model: {
                                  value:
                                    _vm.form.system_BUSINESS_COMMON_CONFIG_15,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "system_BUSINESS_COMMON_CONFIG_15",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.system_BUSINESS_COMMON_CONFIG_15",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "marL25" }, [
                            _c("span", [
                              _vm._v(
                                "若您需要对公司敏感信息进行加密显示(手机号、银行卡号、身份证号)，请勾选"
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    staticClass: "marT10",
                                    model: {
                                      value:
                                        _vm.form
                                          .system_BUSINESS_COMMON_CONFIG_16,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "system_BUSINESS_COMMON_CONFIG_16",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.system_BUSINESS_COMMON_CONFIG_16",
                                    },
                                  },
                                  [_vm._v("启用加密显示")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
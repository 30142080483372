<!-- 注  原先设置的页面在最下方 -->
<template>
  <div class="container x-start">
    <div class="leftBox padd15">
      <div
        class="item x-c cursorP"
        :class="{ clickStyle: leftItem == index }"
        v-for="(item, index) in leftBtnList"
        :key="index"
        @click="itemClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="rightBox">
      <publicParam v-show="leftItem == 0" />
      <dataParam v-show="leftItem == 1" />
      <inventoryParam v-show="leftItem == 2" />
      <produceParam v-show="leftItem == 3" />
    </div>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import shopParam from "@/views/shop/setup/posParam/components/shopParam.vue";
import dataParam from "@/views/system/maintain/param/components/dataParam.vue";

import publicParam from "@/views/system/maintain/param/components/publicParam.vue";
import inventoryParam from "@/views/system/maintain/param/components/inventoryParam.vue";
import produceParam from "@/views/system/maintain/param/components/produceParam.vue";
export default {
  name: "index",
  components: {
    cardTitleCom,
    inventoryParam,
    dataParam,
    publicParam,
    produceParam,
  },
  data() {
    return {
      leftBtnList: ["公共参数", "数据参数", "进销存参数", "生产参数"],
      leftItem: 0, // 左边菜单点击项

      value: false,
      radio: "",
      tableData: [{}],
      isRadio: "2",
      isradio: "",
      checkList: [],
      form: {
        selectValue: "",
      },
      cities: [
        {
          label: "不舍入",
          labelTOW: "1.55-1.55",
          value: "1",
        },
        {
          label: "舍去分",
          labelTOW: "1.55-1.50",
          value: "2",
        },
        {
          label: "舍去角",
          labelTOW: "1.55-1.00",
          value: "3",
        },
        {
          label: "四舍五入到分",
          labelTOW: "1.55-1.55",
          value: "4",
        },
        {
          label: "四舍五入到角",
          labelTOW: "1.55-1.60",
          value: "5",
        },
        {
          label: "四舍五入到元",
          labelTOW: "1.55-2.00",
          value: "6",
        },
      ],
    };
  },
  watch: {
    isRadio(nV) {
      if (nV == 2) {
        this.isradio = "6";
      }
    },
  },
  methods: {
    itemClick(index) {
      console.log("---", index);
      this.leftItem = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  // padding: 6px 10px;
  background: #e9e9e9;
  font-size: 14px;

  .leftBox {
    margin-top: 43px;
    width: 210px;
    height: calc(100vh - 143px);
    background: #ffffff;
    .item {
      height: 50px;
      font-size: 16px;
      font-weight: 550;
    }
  }
  .rightBox {
    width: calc(100% - 220px);
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
  .fontColor {
    color: #949494;
  }
}

.clickStyle {
  background: #e8f1fc;
}
</style>

<!-- <template>
  <div class="param app-container"> -->
<!-- 按钮区域 -->
<!-- <div class="param-btn">
      <el-row :gutter="10">
        <el-col :span="1.5">
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            >新增</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            >删除</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button plain type="info" icon="el-icon-refresh" size="mini" @click="getList">刷新</el-button>
        </el-col>
      </el-row>
    </div> -->
<!-- 表格数据区域 -->
<!-- <div class="param-table">
      <el-table
        ref="table"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="paramId"
        border
        :tree-props="{ children: 'children',hasChildren: 'hasChildren'}" 
        @selection-change="handleSelectionChange"
        @select="select"
        @select-all="selectAll"
        v-loading="loading"
        height="700"
        :default-sort="{ prop: 'sortNo', order: 'ascending' }"
      >
        <el-table-column type="selection" width="50" > </el-table-column>
        <el-table-column prop="paramName" label="参数名称" width="580"  show-overflow-tooltip> 
          <template slot-scope="scope">
            <el-button
               size="mini"
               @click.native.prevent="handleUpdate(scope.row)"
                type="text"
            >
              {{ scope.row.paramName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="paramKey" label="参数键"  show-overflow-tooltip> </el-table-column>
        <el-table-column prop="paramValue" label="参数值" width="200" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="sortNo" label="排序" width="150" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="remark" label="备注"  show-overflow-tooltip> </el-table-column>
      </el-table>
    </div> -->
<!-- 新增/编辑对话框 -->
<!--   <el-dialog :title="title" :visible.sync="openDialog" width="40%" v-dialogDrag="true">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级目录" prop="parentId">
              <treeselect
                v-model="form.parentId"
                :options="tableData"
                :show-count="true"
                placeholder="请选择"
                :normalizer="normalizer"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数名称" prop="paramName">
              <el-input
                v-model="form.paramName"
                placeholder="请输入参数名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="参数键" prop="paramKey">
              <el-input
                v-model="form.paramKey"
                placeholder="请输入参数键"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数值" prop="paramValue">
              <el-input
                v-model="form.paramValue"
                placeholder="请输入参数值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序" prop="sortNo">
              <el-input-number
                v-model="form.sortNo"
                controls-position="right"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitForm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { 
  listParam,
  getParamDetail,
  saveParam,
  updateParam,
  removeParam
} from '@/api/system/param'
export default {
  name: 'Param',
  components: { Treeselect },
  data () {
    return {
      loading: false, //遮罩层
      title: undefined, //对话框标题
      openDialog: false, //对话框开关
      multiple: true, // 删除按钮非多个禁用
      ids:[], //多选框选中的参数id
      name:[], //多选择选中的参数名称
      tableData: [], //业务参数tree数据
      form: {   //表单数据
        parentId: undefined, //上级目录id
        paramId: undefined, //参数主键id
        paramName: undefined, //参数名称
        paramKey: undefined, //参数键
        paramValue: undefined, //参数值
        sortNo: undefined, //排序
        remark: undefined //备注
      },
      //表单校验规则
      rules:{
        paramName: [
          { required: true, message: '参数名称不能为空', trigger: ["blur", "change"] }
        ],
        paramKey: [
          { required: true, message: '参数键不能为空', trigger: ["blur", "change"] }
        ],
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    //tree结构组件数据转换
    normalizer (node) {
      return {
        id: node.paramId,
        label: node.paramName,
        children: node.children
      }
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.paramId)
      this.name = selection.map(item => item.paramName)
      this.multiple = !selection.length
    },
    //多选框选中父级,子级也选中 全部子级取消选中 父级也取消选中
    select (selection,row) {
      if (selection.some(el => row.paramId === el.paramId)){
        if(row.children){
          //当父级选中或者取消选中时,子级遍历选中或者取消选中
        row.children.map( i => {
          this.$refs.table.toggleRowSelection(i , true)
        })
        }
    }else{
       if(row.children){
        row.children.map(i => {
          this.$refs.table.toggleRowSelection(i,false)
        })
       }
    }
      
    },
    //多选框全选中 子级里也选中
    selectAll () {
      this.$refs.table.data.map(i => {
        if(i.children){
          if(!i.isChecked){
            this.$refs.table.toggleRowSelection(i,true)
            i.isChecked = true
            i.children.map(i => {
              this.$refs.table.toggleRowSelection(i, true)
              i.isChecked = true
            })
          }else{
            this.$refs.table.toggleRowSelection(i, false)
            i.isChecked = false
            i.children.map(i => {
              this.$refs.table.toggleRowSelection(i, false)
              i.isChecked = false
            })
          }
        }else{
          if(!i.isChecked) i.isChecked = true
          else i.isChecked = false
        }
      })
    },
    //重置表单事件
    reset () {
      this.form = {
        parentId: undefined, //上级目录id
        paramName: undefined, //参数名称
        paramKey: undefined, //参数键
        paramValue: undefined, //参数值
        sortNo: undefined, //排序
        remark: undefined //备注
      }
      this.resetForm('form')
    },
    //获取业务参数列表数据
    getList () {
      this.loading = true
      listParam().then(response => {
        this.tableData = this.handleTree(response.data, 'paramId')
        this.loading = false
      })
    },
    //新增业务参数事件
    handleAdd () {
      this.reset()
      this.title = '新增业务参数'
      this.openDialog = true
    },
    //编辑业务参数事件
    handleUpdate (row) {
      this.reset()
      const paramId = row.paramId
      getParamDetail(paramId).then(response => {
        this.form = response.data
        this.form.parentId = response.data.parentId === 0 ? undefined :response.data.parentId
        this.title = '编辑业务参数'
        this.openDialog = true
      })
    },
    //删除业务参数事件
    handleDelete () {
      const paramIds = this.ids
      this.$modal
        .confirm('是否确认删除业务参数为"' + this.name + '"的数据项？')
        .then(function () {
          return removeParam(paramIds)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.paramId != undefined) {
            updateParam(this.form).then(response => {
              this.openDialog = false
              this.$modal.msgSuccess('修改成功')
              this.getList()
            })
          } else {
            saveParam(this.form).then(response => {
              this.openDialog = false
              this.$modal.msgSuccess('新增成功')
              this.getList()
            })
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
//按钮区域
.param-btn {
  margin-bottom: 20px;
}
//表单区域
.form {
  //参数值/排序
  ::v-deep .el-input-number--medium {
    width: 100%;
  }
}
</style> -->

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.data, function (item) {
        return _c("div", { key: item.upgradeId, staticClass: "view-item" }, [
          _c("div", { staticClass: "title1" }, [
            _vm._v(
              _vm._s(
                _vm.parseTime(item.createTime, "{y}-{m}-{d}") +
                  "更新（" +
                  item.productName +
                  "）"
              )
            ),
          ]),
          _c("div", [_vm._v(_vm._s(item.upgradeTitle))]),
          _c("div", { staticClass: "ql-container" }, [
            _c("div", {
              staticClass: "ql-editor",
              domProps: { innerHTML: _vm._s(item.upgradeContent) },
            }),
          ]),
        ])
      }),
      0
    ),
    _c(
      "ul",
      { staticClass: "navs" },
      _vm._l(_vm.data, function (item, i) {
        return _c(
          "li",
          {
            key: item.upgradeId,
            class: { active: _vm.active === i },
            on: {
              click: function ($event) {
                return _vm.scrollTo(i)
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.parseTime(item.createTime, "{y}-{m}-{d}") +
                  "更新（" +
                  item.productName +
                  "）"
              )
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
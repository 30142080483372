var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showSearch,
                        expression: "showSearch",
                      },
                    ],
                    ref: "queryForm",
                    attrs: {
                      model: _vm.queryParams,
                      size: "small",
                      inline: true,
                    },
                  },
                  [
                    _c("div", { staticClass: "x-bc" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "菜单名称", prop: "menuName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入菜单名称",
                                  clearable: "",
                                  size: "mini",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleQuery($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryParams.menuName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "menuName", $$v)
                                  },
                                  expression: "queryParams.menuName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", prop: "status" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "菜单状态",
                                    clearable: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.sys_normal_disable,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.handleQuery },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "brandInfo", attrs: { cardTitle: "菜单管理" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    isAddBtn: false,
                    isDeleteBtn: false,
                    isExportBtn: false,
                    isRefreshBtn: false,
                    isExpandAllBtn: true,
                  },
                  on: { toggleExpandAll: _vm.toggleExpandAll },
                  scopedSlots: _vm._u([
                    {
                      key: "specialDiyBtn",
                      fn: function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "mini",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm.refreshTable
                  ? _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: {
                          data: _vm.menuList,
                          "row-key": "menuId",
                          "default-expand-all": _vm.isExpandAll,
                          "tree-props": {
                            children: "children",
                            hasChildren: "hasChildren",
                          },
                          border: "",
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "menuName",
                            label: "菜单名称",
                            "show-overflow-tooltip": true,
                            width: "260",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "icon",
                            label: "图标",
                            align: "center",
                            width: "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": scope.row.icon },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3094025326
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "orderNum",
                            label: "排序",
                            width: "60",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "perms",
                            label: "权限标识",
                            align: "center",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "component",
                            label: "组件路径",
                            align: "center",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "status",
                            label: "状态",
                            width: "80",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("dict-tag", {
                                      attrs: {
                                        options:
                                          _vm.dict.type.sys_normal_disable,
                                        value: scope.row.status,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2802338569
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "创建时间",
                            align: "center",
                            prop: "createTime",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parseTime(scope.row.createTime)
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3078210614
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            "class-name": "small-padding fixed-width",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: ["system:menu:edit"],
                                            expression: "['system:menu:edit']",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-edit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleUpdate(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: ["system:menu:add"],
                                            expression: "['system:menu:add']",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-plus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdd(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("新增")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: ["system:menu:remove"],
                                            expression:
                                              "['system:menu:remove']",
                                          },
                                        ],
                                        attrs: {
                                          size: "mini",
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1292372230
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 680, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "上级菜单" } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.menuOptions,
                                    normalizer: _vm.normalizer,
                                    "show-count": true,
                                    placeholder: "选择上级菜单",
                                  },
                                  model: {
                                    value: _vm.form.parentId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "parentId", $$v)
                                    },
                                    expression: "form.parentId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "菜单类型", prop: "menuType" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.menuType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "menuType", $$v)
                                      },
                                      expression: "form.menuType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "M" } }, [
                                      _vm._v("目录"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "C" } }, [
                                      _vm._v("菜单"),
                                    ]),
                                    _c("el-radio", { attrs: { label: "F" } }, [
                                      _vm._v("按钮"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "菜单图标", prop: "icon" },
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom-start",
                                          width: "460",
                                          trigger: "click",
                                        },
                                        on: {
                                          show: function ($event) {
                                            return _vm.$refs[
                                              "iconSelect"
                                            ].reset()
                                          },
                                        },
                                      },
                                      [
                                        _c("IconSelect", {
                                          ref: "iconSelect",
                                          on: { selected: _vm.selected },
                                        }),
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              placeholder: "点击选择图标",
                                              readonly: "",
                                            },
                                            slot: "reference",
                                            model: {
                                              value: _vm.form.icon,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "icon", $$v)
                                              },
                                              expression: "form.icon",
                                            },
                                          },
                                          [
                                            _vm.form.icon
                                              ? _c("svg-icon", {
                                                  staticClass: "el-input__icon",
                                                  staticStyle: {
                                                    height: "32px",
                                                    width: "16px",
                                                  },
                                                  attrs: {
                                                    slot: "prefix",
                                                    "icon-class": _vm.form.icon,
                                                  },
                                                  slot: "prefix",
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-search el-input__icon",
                                                  attrs: { slot: "prefix" },
                                                  slot: "prefix",
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "菜单图标图片",
                                      prop: "menuImageUrl",
                                    },
                                  },
                                  [
                                    _c("SingleImageUpload", {
                                      attrs: { fileList: _vm.fileList },
                                      on: {
                                        getImageUrl: function ($event) {
                                          return _vm.handleImageUrl(
                                            $event,
                                            "add"
                                          )
                                        },
                                        delImage: function ($event) {
                                          return _vm.handleImageUrl(
                                            $event,
                                            "del"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "菜单名称", prop: "menuName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入菜单名称" },
                                  model: {
                                    value: _vm.form.menuName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "menuName", $$v)
                                    },
                                    expression: "form.menuName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "显示排序", prop: "orderNum" },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    "controls-position": "right",
                                    min: 0,
                                  },
                                  model: {
                                    value: _vm.form.orderNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "orderNum", $$v)
                                    },
                                    expression: "form.orderNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "选择是外链则路由地址需要以`http(s)://`开头",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 是否外链 "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.isFrame,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isFrame", $$v)
                                          },
                                          expression: "form.isFrame",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "0" } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "1" } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "path" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 路由地址 "),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入路由地址" },
                                      model: {
                                        value: _vm.form.path,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "path", $$v)
                                        },
                                        expression: "form.path",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType == "C"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "component" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "访问的组件路径，如：`system/user/index`，默认在`views`目录下",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 组件路径 "),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入组件路径" },
                                      model: {
                                        value: _vm.form.component,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "component", $$v)
                                        },
                                        expression: "form.component",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType != "M"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入权限标识",
                                        maxlength: "100",
                                      },
                                      model: {
                                        value: _vm.form.perms,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "perms", $$v)
                                        },
                                        expression: "form.perms",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:staff:list')`)",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 权限字符 "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType == "C"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入路由参数",
                                        maxlength: "255",
                                      },
                                      model: {
                                        value: _vm.form.query,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "query", $$v)
                                        },
                                        expression: "form.query",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                '访问路由的默认传递参数，如：`{"id": 1, "name": "ry"}`',
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 路由参数 "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType == "C"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 是否缓存 "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.isCache,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isCache", $$v)
                                          },
                                          expression: "form.isCache",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "0" } },
                                          [_vm._v("缓存")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "1" } },
                                          [_vm._v("不缓存")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "选择隐藏则路由将不会出现在侧边栏，但仍然可以访问",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 显示状态 "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.visible,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "visible", $$v)
                                          },
                                          expression: "form.visible",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.sys_show_hide,
                                        function (dict) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: dict.value,
                                              attrs: { label: dict.value },
                                            },
                                            [_vm._v(_vm._s(dict.label))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.menuType != "F"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "选择停用则路由将不会出现在侧边栏，也不能被访问",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" 菜单状态 "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.status,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "status", $$v)
                                          },
                                          expression: "form.status",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.sys_normal_disable,
                                        function (dict) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: dict.value,
                                              attrs: { label: dict.value },
                                            },
                                            [_vm._v(_vm._s(dict.label))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!-- 注  原先的页面在最下方，现用新设计的 -->
<template>
  <div class="container">
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="configSave">保存</el-button>
    </div>

    <cardTitleCom cardTitle="公共参数">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f">
            <!-- :model="ruleForm" -->
            <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <div>企业信息</div>
              <el-form-item label="公司名称:" class="marT10">
                <el-input
                  v-model="form.system_BUSINESS_COMMON_CONFIG_11"
                  ref="companyNameInput"
                  class="inputWidthM marL20"
                  size="mini"
                  placeholder="请输入公司名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="公司地址:">
                <el-input
                  class="inputWidthM marL20"
                  size="mini"
                  v-model="form.system_BUSINESS_COMMON_CONFIG_12"
                  placeholder="请输入公司地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="公司电话:">
                <el-input
                  class="inputWidthM marL20"
                  type="number"
                  size="mini"
                  v-model="form.system_BUSINESS_COMMON_CONFIG_13"
                  @input="handleInput"
                  placeholder="请输入公司电话"
                >
                </el-input>
              </el-form-item>

              <el-form-item label="公司传真:">
                <el-input
                  class="inputWidthM marL20"
                  size="mini"
                  v-model="form.system_BUSINESS_COMMON_CONFIG_14"
                  placeholder="请输入公司传真"
                ></el-input>
              </el-form-item>

              <el-form-item label="公司邮编:">
                <el-input
                  class="inputWidthM marL20 compact-form-item"
                  type="number"
                  size="mini"
                  v-model="form.system_BUSINESS_COMMON_CONFIG_15"
                  placeholder="请输入公司邮编"
                ></el-input>
              </el-form-item>

              <div class="marL25">
                <span
                  >若您需要对公司敏感信息进行加密显示(手机号、银行卡号、身份证号)，请勾选</span
                >
                <div class="x-f">
                  <el-checkbox
                    v-model="form.system_BUSINESS_COMMON_CONFIG_16"
                    class="marT10"
                    >启用加密显示</el-checkbox
                  >
                  <!-- <el-checkbox-group
                    v-model="form.system_BUSINESS_COMMON_CONFIG_16"
                  >
                    <el-checkbox :label="1">启用加密显示</el-checkbox>
                  </el-checkbox-group> -->

                  <!-- <el-tooltip
                    class="item marL10 marT10"
                    effect="dark"
                    :content="content2"
                    placement="top"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip> -->
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; // 卡片标题
import bottomPage from "@/views/components/bottomPage"; // 底部分页
import {
  publicListAPI,
  publicAddUpdateAPI,
} from "@/api/system/maintain/param/publicParam";
export default {
  name: "publicParam",
  components: {
    cardTitleCom,
    bottomPage,
  },
  data() {
    return {
      // ruleForm: {
      //   name: "",
      // },
      // rules: {
      //   name: [
      //     { required: true, message: "公司名称不能为空", trigger: "blur" },
      //   ],
      // }, // 验证规则
      checked: [],
      radio: "",
      companyName: "", // 公司名称
      value: true,
      content2: "提示信息",
      input: "",
      form: {
        system_BUSINESS_COMMON_CONFIG_11: "",
        system_BUSINESS_COMMON_CONFIG_12: "",
        system_BUSINESS_COMMON_CONFIG_13: "",
        system_BUSINESS_COMMON_CONFIG_15: "",

        system_BUSINESS_COMMON_CONFIG_16: "",
      },
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    handleInput(value) {
      let inputValue = String(value).slice(0, 11); // 截取前11个字符
      if (inputValue.length > 0 && isNaN(inputValue)) {
        // 如果输入的值不是有效的数字，则将其设为为空字符串
        inputValue = "";
      }
      this.form.system_BUSINESS_COMMON_CONFIG_13 = inputValue; // 更新表单数据
    },
    getDetails() {
      publicListAPI().then((res) => {
        // console.log("售后：", res);
        // let { receivingTime } = res.data;
        // this.receivingTime = receivingTime;
        this.form = res.data;
      });
    },
    configSave() {
      publicAddUpdateAPI(this.form).then((res) => {
        // console.log('售后修改成功', res)
        this.$message.success(res.msg);
        this.getDetails();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.compact-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.container {
  padding: 8px 10px;
  background: #e9e9e9;
  font-size: 14px;
}
.btnTOP {
  text-align: right;
  padding: 0 10px 6px 0;
}
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::v-deep .underline-input .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "btnTOP" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.configSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "数据参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.form, rules: _vm.rules },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "数量小数位保留",
                              prop: "system_BUSINESS_DATA_CONFIG_11",
                              "label-width": "130px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input",
                              staticStyle: { width: "80px" },
                              attrs: { maxlength: "1", size: "mini" },
                              model: {
                                value: _vm.form.system_BUSINESS_DATA_CONFIG_11,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_11",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_11",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "单价小数位保留",
                              prop: "system_BUSINESS_DATA_CONFIG_12",
                              "label-width": "130px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input",
                              staticStyle: { width: "80px" },
                              attrs: { size: "mini", maxlength: "1" },
                              model: {
                                value: _vm.form.system_BUSINESS_DATA_CONFIG_12,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_12",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_12",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "金额小数位保留",
                              prop: "system_BUSINESS_DATA_CONFIG_13",
                              "label-width": "130px",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input",
                              staticStyle: { width: "80px" },
                              attrs: { size: "mini", maxlength: "1" },
                              model: {
                                value: _vm.form.system_BUSINESS_DATA_CONFIG_13,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_13",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_13",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        staticClass: "bg-Color",
        attrs: { cardTitle: "基础资料参数" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      [
                        _c("el-form-item", {
                          staticClass: "compact-form-item",
                          attrs: {
                            label:
                              "若您希望在新增商品时自动填充税率，请设置默认值:",
                          },
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "compact-form-item",
                            attrs: { label: "默认增值税税率为:" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input",
                              staticStyle: { width: "80px" },
                              attrs: { size: "mini", type: "number" },
                              model: {
                                value:
                                  _vm.form.system_BUSINESS_DATA_CONFIG_BASIC_21,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_BASIC_21",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_BASIC_21",
                              },
                            }),
                            _c("span", { staticClass: "marL10" }, [
                              _vm._v("%"),
                            ]),
                          ],
                          1
                        ),
                        _c("el-form-item", {
                          staticClass: "marT15 compact-form-item",
                          attrs: {
                            label:
                              "若您希望新增商品时，自动标记为新品，请设置默认值:",
                          },
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "compact-form-item",
                            attrs: { label: "商品上架" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "input",
                              staticStyle: { width: "80px" },
                              attrs: { size: "mini", min: 1, type: "number" },
                              model: {
                                value:
                                  _vm.form.system_BUSINESS_DATA_CONFIG_BASIC_22,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_BASIC_22",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_BASIC_22",
                              },
                            }),
                            _c("span", { staticClass: "marL10" }, [
                              _vm._v("天内为新品"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "首页营业数据金额隐藏" } },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                              },
                              model: {
                                value:
                                  _vm.form.system_BUSINESS_DATA_CONFIG_BASIC_23,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "system_BUSINESS_DATA_CONFIG_BASIC_23",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.system_BUSINESS_DATA_CONFIG_BASIC_23",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }